import React from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql} from 'gatsby';


const IndexPage = () => {

  const {t} = useTranslation();

  return (
      <Layout>
        <Seo
            title={t('sustainability_emissions')}
            description={t('des_sustainability_emissions')}
        />
        <div className="patents emissions">
          <section className="hero">
            <div className="container">
              <h1>
                <Trans>sustain_and_emissions</Trans>
              </h1>
              <h2>
                <Trans>sustainability</Trans>
              </h2>
              <p>
                <Trans>sust_para1</Trans>
              </p>
              <p>
                <Trans>sust_para2</Trans>
              </p>
                <table>
                  <thead>
                  <tr>
                    <th>
                      <Trans>substance</Trans>
                    </th>
                    <th>
                      <Trans>maximum_value</Trans>
                    </th>
                  </tr>
                  </thead>
                  <tr>
                    <td><Trans>cadmium</Trans></td>
                    <td><Trans>maximum_value_result_100</Trans></td>
                  </tr>
                  <tr>
                    <td><Trans>lead</Trans></td>
                    <td><Trans>maximum_value_result_1000</Trans></td>
                  </tr>
                  <tr>
                    <td><Trans>mercury</Trans></td>
                    <td><Trans>maximum_value_result_1000</Trans></td>
                  </tr>
                  <tr>
                    <td><Trans>hexavalent</Trans></td>
                    <td><Trans>maximum_value_result_1000</Trans></td>
                  </tr>
                  <tr>
                    <td><Trans>poly_diphenyl</Trans></td>
                    <td><Trans>maximum_value_result_1000</Trans></td>
                  </tr>
                  <tr>
                    <td><Trans>poly_biphenyl</Trans></td>
                    <td><Trans>maximum_value_result_1000</Trans></td>
                  </tr>
                  <tr>
                    <td><Trans>bis</Trans></td>
                    <td><Trans>maximum_value_result_1000</Trans></td>
                  </tr>
                  <tr>
                    <td><Trans>butyl</Trans></td>
                    <td><Trans>maximum_value_result_1000</Trans></td>
                  </tr>
                  <tr>
                    <td><Trans>dibutyl</Trans></td>
                    <td><Trans>maximum_value_result_1000</Trans></td>
                  </tr>
                  <tr>
                    <td><Trans>diisobutyl</Trans></td>
                    <td><Trans>maximum_value_result_1000</Trans></td>
                  </tr>
                </table>
              <p>
                <Trans>sust_para3</Trans>
              </p>
              <h2 className={'emissions-headline'}>
                <Trans>emissions</Trans>
              </h2>
              <table className={'emissions-table'}>
                <thead>
                <tr>
                  <th colSpan={4}>
                    <Trans>2021_emissions</Trans>
                  </th>
                </tr>
                </thead>
                <tr>
                  <td colSpan={2}><Trans>calculated</Trans></td>
                  <td dangerouslySetInnerHTML={{__html: t('metric_tons')}} />
                  <td>% <Trans>total_footprint</Trans></td>
                </tr>
                <tr>
                  <td><Trans>scope1</Trans></td>
                  <td><Trans>scope1_para</Trans></td>
                  <td>114.96</td>
                  <td>15%</td>
                </tr>
                <tr>
                  <td><Trans>scope2</Trans></td>
                  <td><Trans>scope2_para</Trans></td>
                  <td>677.2053</td>
                  <td>85%</td>
                </tr>
              </table>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
